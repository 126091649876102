import React from 'react';
import moment from 'moment';
import { Row, Col, List, Dropdown, Menu } from 'antd';

import DownloadFlatIcon from 'components/Icons/DownloadFlatIcon';
import DropdownIcon from 'components/Icons/DropdownIcon';

import './BillingInfo.scss';

const Skeleton = (props) => <div className="skelton" {...props}></div>;

const BillingInfo = ({ loadingInvoices, invoices, invoiceLanguage }) => {
  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const renderInvoiceListItem = (date, description, amount, button) => (
    <Row className="billing-list-item item" justify="middle" align="top">
      <Col
        xs={{ span: 24, offset: 0, order: 2 }}
        sm={{ span: 24, offset: 0, order: 2 }}
        md={{ span: 24, offset: 0, order: 2 }}
        lg={{ span: 4, offset: 0, order: 1 }}
        xl={{ span: 4, offset: 0, order: 1 }}
        className="value">
        {date}
      </Col>
      <Col
        xs={{ span: 24, offset: 0, order: 3 }}
        sm={{ span: 24, offset: 0, order: 3 }}
        md={{ span: 24, offset: 0, order: 3 }}
        lg={{ span: 12, offset: 0, order: 2 }}
        xl={{ span: 12, offset: 0, order: 2 }}
        className="value">
        {description}
      </Col>
      <Col
        xs={{ span: 24, offset: 0, order: 4 }}
        sm={{ span: 24, offset: 0, order: 4 }}
        md={{ span: 24, offset: 0, order: 4 }}
        lg={{ span: 3, offset: 0, order: 3 }}
        xl={{ span: 3, offset: 0, order: 3 }}
        className="value">
        {amount}
      </Col>
      <Col
        xs={{ span: 24, offset: 0, order: 5 }}
        sm={{ span: 24, offset: 0, order: 5 }}
        md={{ span: 24, offset: 0, order: 5 }}
        lg={{ span: 5, offset: 0, order: 4 }}
        xl={{ span: 5, offset: 0, order: 4 }}
        className="invoice-value value">
        {button}
      </Col>
    </Row>
  );

  const downloadMenu = (invoice) => (
    <Menu
      className="download-invoice-menu"
      onClick={(event) => window.open(invoice.url[event.key])}>
      <Menu.Item key="he">Download in Hebrew</Menu.Item>
    </Menu>
  );

  return (
    <Row className="billing-info">
      <h2 className="plans-content-header">Billing History</h2>
      <Row className="billing-list-header" justify="middle" align="top" gutter={8}>
        <Col xs={24} sm={24} md={24} lg={4} xl={4} className="header-value">
          Date
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="header-value">
          Name
        </Col>
        <Col xs={24} sm={24} md={24} lg={3} xl={3} className="header-value">
          Amount
        </Col>
        <Col xs={24} sm={24} md={24} lg={5} xl={5} className="header-value invoice-header-value">
          <span className="above-md">Invoice</span>
        </Col>
      </Row>

      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 6,
          itemRender: itemRender,
        }}
        dataSource={invoices?.fetchUserInvoices || []}
        renderItem={(invoice) => (
          <List.Item>
            {!loadingInvoices
              ? renderInvoiceListItem(
                  moment(new Date(invoice.documentDate)).format('MM/DD/YYYY'),
                  invoice.description,
                  `$${invoice.amount}`,
                  <Dropdown.Button
                    onClick={() => window.open(invoice.url.en)}
                    overlay={() => (invoiceLanguage === 'he' ? downloadMenu(invoice) : '')}
                    icon={invoiceLanguage !== 'he' ? null : <DropdownIcon />}>
                    <DownloadFlatIcon /> Download
                  </Dropdown.Button>
                )
              : renderInvoiceListItem(<Skeleton />, <Skeleton />, <Skeleton />, <Skeleton />)}
          </List.Item>
        )}
      />
    </Row>
  );
};

export default BillingInfo;
