import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import CloseIcon from 'components/Icons/CloseIcon';

const Popconfirm = ({
  title,
  body,
  visible,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  showIcon,
  modalClassName,
  closable,
  okButtonProps
}) => (
  <Modal
    className={`confirm-modal ${modalClassName}`}
    centered
    visible={visible}
    onCancel={onCancel}
    closeIcon={<CloseIcon className="close-icon" />}
    closable={closable}
    showIcon={showIcon}
    footer={null}>
    <h3 className="title">{title}</h3>
    <div className="divider" />

    <div className="body">{body}</div>

    <div className="button-wrapper">
      {confirmText && (
        <Button
          loading={okButtonProps?.loading}
          className="large-btn secondary-btn"
          onClick={onConfirm}>
          {confirmText}
        </Button>
      )}
      {cancelText && (
        <Button className="large-btn grey-btn" onClick={onCancel}>
          {cancelText}
        </Button>
      )}
    </div>
  </Modal>
);

Popconfirm.defaultProps = {
  visible: false,
  showIcon: true,
  modalClassName: '',
  confirmText: '',
  cancelText: '',
  onCancel: () => { },
  onConfirm: () => { },
};

Popconfirm.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  visible: PropTypes.bool,
  showIcon: PropTypes.bool,
  modalClassName: PropTypes.string,
};

export default Popconfirm;
