import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Divider, Dropdown, Menu, Button, Tooltip, Select } from 'antd';

import { useIntercom } from 'react-use-intercom';
import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import WebFont from 'webfontloader';
import * as _ from 'lodash';

import IncrementIcon from 'components/Icons/IncrementIcon';
import DecrementIcon from 'components/Icons/DecrementIcon';

import {
  fontListEnglish,
  fontListHebrew,
  fontSizes,
  defaultHebrewBodySettings,
  defaultBodySettings,
} from 'pages/Proposal/constants/constants';
import { fontLoadedVar } from 'graphql/cache';
import DropdownIcon from 'components/Icons/DropdownIcon';
const { Option } = Select;


const weightMaps = {
  100: 'Thin',
  200: 'Extra light',
  300: 'Light',
  400: 'Normal',
  500: 'Medium',
  600: 'Semi bold',
  700: 'Bold',
  '700italic': 'Bold italic',
  800: 'Black',
  '800italic': 'Black italic',
  900: 'Extra black',
  italic: 'Italic',
};

const cleanString = (s) => s.replace(/^["']|["']$/g, '');

const FontTools = ({
  bodyFont,
  language,
  editorState,
  onToggleFontInlineStyle,
  user,
  handleFontMenu,
}) => {
  const fontSelected = useRef();
  const fontLoaded = useReactiveVar(fontLoadedVar);
  const { trackEvent } = useIntercom();
  const selection = editorState.getSelection();
  const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
  const currentStyle = block ? editorState?.getCurrentInlineStyle() : '';

  const [fontFamilies, setFontFamilies] = useState([]);

  useEffect(() => {
    if (language?.toLowerCase() === 'hebrew') {
      setFontFamilies((user?.uploadedFonts || []).concat(fontListHebrew));
    } else {
      const uploadedFontsMapped = (user?.uploadedFonts || []).map((font) => ({
        ...font,
        weights: font.weights.filter((fw) => typeof fw === 'number'),
      }));
  
      const fontListEnglishMapped = fontListEnglish.map((f) => ({
        ...f,
        weights: f.weights.filter((fw) => typeof fw === 'number'),
      }));
  
      setFontFamilies(uploadedFontsMapped.concat(fontListEnglishMapped));
    }
  }, [language, user]);

  const [selectedFontFamily, setSelectedFontFamily] = useState(
    fontFamilies.find((font) => currentStyle && currentStyle.has(font.family)) ||
      fontFamilies[bodyFont?.family] ||
      (language?.toLowerCase() === 'hebrew' ? defaultHebrewBodySettings : defaultBodySettings)
  );

  const [selectedSize, setSelectedSize] = useState(
    fontSizes.find((size) => currentStyle && currentStyle.has(`${size}`)) ||
      bodyFont?.fontSize ||
      selectedFontFamily.fontSize ||
      '20px'
  );

  useEffect(() => {
    const activeEditor = document.querySelector('.rich-editor.active');
    const parentStyle = window.getComputedStyle(activeEditor.parentElement);
    const fontFamily = cleanString(parentStyle.getPropertyValue('font-family'));
    const fontSize = parentStyle.getPropertyValue('font-size');
    if (activeEditor && activeEditor.parentElement) {
      const matchedFontFamily =
        fontFamilies.find((font) => currentStyle && currentStyle.has(font.family)) ||
        fontFamilies.find((font) => cleanString(font.family) === fontFamily) ||
        fontFamilies[bodyFont?.family] ||
        (language?.toLowerCase() === 'hebrew' ? defaultHebrewBodySettings : defaultBodySettings);
      if (selectedFontFamily !== matchedFontFamily) {
        setSelectedFontFamily(matchedFontFamily);
      }
    }
    const matchedFontSize =
      fontSizes.find((size) => currentStyle && currentStyle.has(`${size}`)) || `${fontSize}` ||
      bodyFont?.fontSize ||
      selectedFontFamily.fontSize ||
      '20px';
  
    if (selectedSize !== matchedFontSize) {
      setSelectedSize(matchedFontSize);
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyFont, currentStyle, fontFamilies, language, selectedFontFamily, selectedSize, fontSizes]);

  const handleChange = (type, value, weight) => {
    if (type === 'family') {
      const selectedFont = fontFamilies.find((font) => font.family === value);
      setSelectedFontFamily(selectedFont);
    } else if (type === 'size') {
      setSelectedSize(value);
    }

    trackEvent('fonts-updated');

    onToggleFontInlineStyle({ inlineStyle: value, type, fontFamilies, weight });
  };

  const increaseFontSize = (e) => {
    e.preventDefault();
    const currentIndex = fontSizes.indexOf(selectedSize);
    const nextIndex = currentIndex < fontSizes.length - 1 ? currentIndex + 1 : currentIndex;
    const newSize = fontSizes[nextIndex];
    handleChange('size', newSize);
  };
  
  const decreaseFontSize = (e) => {
    e.preventDefault();
    const currentIndex = fontSizes.indexOf(selectedSize);
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
    const newSize = fontSizes[prevIndex];
    handleChange('size', newSize);
  };

  useEffect(() => {
    if (!fontLoaded && fontFamilies.length) {
      fontLoadedVar(true);

      const fontsDefault = [];
      fontFamilies.forEach((f) => {
        f.weights.forEach((w) => {
          fontsDefault.push({
            family: `${f.family}:${w}${
              language?.toLowerCase() === 'hebrew' && f.family !== 'Arial' ? ':hebrew' : ''
            }`,
            source: f.source,
          });
        });
      });

      WebFont.load({
        google: {
          families: _.compact(fontsDefault.map((f) => f.source === 'Google' && f.family)),
        },
        custom: {
          families: ['Open Sans Hebrew'],
        },
      });
    }
  }, [fontFamilies, fontLoaded, language]);


  const fontSizeMenuList = (
    <Menu selectedKeys={[selectedSize]} className="rich-editor-list-option font-size">
      {fontSizes.map((fontSize) => (
        <Menu.Item
          key={fontSize}
          onMouseDown={(e) => {
            e.preventDefault();
            handleChange('size', fontSize);
          }}>
          <span className="menu-item">{parseInt(fontSize)}</span>
        </Menu.Item>
      ))}
    </Menu>
  );


  const onFontDropdownVisibleChange = (value) => {
    if (value) {
      handleFontMenu('open');
    } else {
      setTimeout(() => {
        if (fontSelected.current) {
          fontSelected.current = false;
          return handleFontMenu('changed-font');
        }

        handleFontMenu('close');
      }, 100);
    }
  };

  const onFontChange = (value) => {
    if (value) {
      fontSelected.current = true;
      handleChange(value, 'family');
    }
  };

  const onFilter = (input, option) =>
    (option?.value ?? '').toLowerCase().includes(input.toLowerCase());


  return (
    <>
      <Select
        showSearch
        notFoundContent={null}
        placeholder="Verdana"
        className="rich-editor-list font-family"
        suffixIcon={<DropdownIcon className="rich-editor-dropdown-icon" />}
        dropdownClassName="font-family-list"
        optionLabelProp="label"
        value={selectedFontFamily?.family || bodyFont.family}
        filterOption={onFilter}
        selected={selectedFontFamily?.family || bodyFont.family}
        onChange={onFontChange}
        onMouseDown={(e) => e.stopPropagation()}
        onDropdownVisibleChange={onFontDropdownVisibleChange}
        >
        {fontFamilies.map((font) => (
          <Option key={font.family} label={font.family} style={{ fontFamily: font.family }}>
            <MenuItem handleChange={handleChange} fontFamily={font} />
          </Option>
        ))}
      </Select>




      <div className="button-group">
        <Dropdown
          overlay={fontSizeMenuList}
          trigger={['click']}
          className="rich-editor-list font-size">
          <Button onMouseDown={(e) => e.preventDefault()}>
            <span>{parseInt(selectedSize)}</span>
            <DropdownIcon className="rich-editor-dropdown-icon" />
          </Button>
        </Dropdown>

        <div className="increment-container" onMouseDown={(e) => e.preventDefault()}>
          <IncrementIcon onMouseDown={increaseFontSize} />
          <DecrementIcon onMouseDown={decreaseFontSize} />
        </div> 
      </div>

      <Divider type="vertical" />
    </>
  );
};

FontTools.defaultProps = {
  bodyFont: '',
};

FontTools.propTypes = {
  language: PropTypes.string.isRequired,
  bodyFont: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  editorState: PropTypes.instanceOf(Object).isRequired,
  onToggleFontInlineStyle: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default FontTools;

const MenuItem = ({ handleChange, fontFamily }) => {
  return (
    <Fragment>
      {fontFamily?.weights.length > 1 ? (
        <Tooltip
          destroyTooltipOnHide={true}
          placement="rightTop"
          overlayClassName="menu-subitem-tooltip"
          title={fontFamily.weights.map((fontWeight) => (
            <Fragment key={fontWeight}>
              {typeof fontWeight === 'number' && (
                <li
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleChange('family', fontFamily.family, fontWeight);
                  }}
                  style={{ fontFamily: fontFamily.family, fontWeight }}>
                  {weightMaps[fontWeight] || fontWeight}
                </li>
              )}
            </Fragment>
          ))}>
          <span
            className="menu-item"
            onMouseDown={(e) => {
              e.preventDefault();
              handleChange('family', fontFamily.family, 400);
            }}>
            <span className="title">{fontFamily.family}</span>
            <DropdownIcon className="arrow-icon" />
          </span>
        </Tooltip>
      ) : (
        <span
          className="menu-item"
          onMouseDown={(e) => {
            e.preventDefault();
            handleChange('family', fontFamily.family, 400);
          }}>
          {fontFamily?.family}
        </span>
      )}
    </Fragment>
  );
};
