import React, { useRef, useState } from 'react';
import { Col, Row, Divider, Popover, Button, Tooltip } from 'antd';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

import DeleteIcon from 'components/Icons/DeleteIcon';
import RoundDragIcon from 'components/Icons/RoundDragIcon';
import AddColumnIcon from 'components/Icons/AddColumnIcon';
import AddRowIcon from 'components/Icons/AddRowIcon';
import ResizeIcon from 'components/Icons/ResizeIcon';

import '../index.scss';

const ComponentWrapper = ({
  sectionName,
  setDraggingElement,
  children,
  blockKey,
  showDrag,
  onFocus,
  onBlur,
  showActionButtons,
  duplicate,
  remove,
  addRow,
  addColumn,
  onColorChange,
  onEdit,
  onDragMouseDown,
  showDuplicate,
  showEdit,
  showDelete,
  isDraggable,
  handleColumn,
  config,
  handleEditComponent,
  showTableActions,
  showTestimonialActions,
  componentType,
  showImageActions,
  isSaving,
  componentWrapperStyle,
  setDropDisabled,
  toolbarClassName,
  toolbar,
  isSection,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [toolOpen, setToolOpen] = useState(false);
  const componentWrapperRef = useRef();

  const handleMouseLeave = () => {
    if (onBlur && !showImageActions) {
      onBlur();
    }
  };

  const handleDrag = (event, type) => {
    if (event.target.className.match('rich-editor-components-wrapper')) {
      if (type === 'start') {
        const img = new Image();
        event.dataTransfer.setDragImage(img, 100, 100);

        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.effectAllowed = 'all';
        event.dataTransfer.setData('text', blockKey);

        // Can be used in future to move elements between sections
        // event.dataTransfer.setData('sectionName', sectionName);

        handleEditComponent(false, 'drag');
        setIsDragging(true);
        setDropDisabled(sectionName);
        setDraggingElement('start');
      } else {
        handleEditComponent(true, 'drag');
        setIsDragging(false);
        setDropDisabled('');
        setDraggingElement('end');
      }
    }
  };

  const handleDeleteVisibleChange = (visible) => {
    setToolOpen(visible ? 'delete' : false);
    if (onBlur) {
      onBlur();
    }
  };

  const setActionButtonsPosition = () => {
    const componentWrapperBound = componentWrapperRef.current?.getBoundingClientRect();
    const sectionBound = componentWrapperRef.current
      ?.closest('.section-wrapper')
      .getBoundingClientRect();

    if (componentWrapperBound && sectionBound) {
      //  for image toolbar render in custom location
      if (toolbarClassName === 'image-toolbar') {
        const offsetX = componentWrapperBound.left - sectionBound.left;
        if (offsetX > 600) {
          return {
            right: 0,
          };
        }
        // render in center
        else if (offsetX > 250) {
          return {
            right: '50%',
            transform: 'translateX(50%)',
          };
        } else {
          if (isSection) {
            return {
              left: 10,
            };
          }

          return {
            left: -40,
          };
        }
      }

      const toolbarRight = componentWrapperBound.left + 240;

      if (toolbarRight + 30 >= sectionBound.right) {
        const left = toolbarRight - sectionBound.right + 15;
        return {
          left: `-${left}px`,
        };
      }

      const toolbarLeft = componentWrapperBound.right - 240;

      if (toolbarLeft - 30 <= sectionBound.left) {
        const right = toolbarLeft - sectionBound.left - 15;
        return {
          right: `${right}px`,
        };
      }
    }
    return null;
  };

  return (
    <div
      ref={componentWrapperRef}
      className={`rich-editor-components-wrapper 
      ${config?.alignment ? 'rich-editor-components-wrapper-' + config.alignment : ''}`}
      onClick={onFocus}
      onBlur={onBlur ? () => onBlur() : null}
      onMouseLeave={onBlur ? () => handleMouseLeave() : null}
      onDragStart={showDrag ? (event) => handleDrag(event, 'start') : null}
      onDragEnd={showDrag ? (event) => handleDrag(event, 'end') : null}
      draggable={isDraggable}
      style={componentWrapperStyle}>
      {showDrag && (
        <Tooltip title="Move">
          <RoundDragIcon
            className={`rich-editor-components-draggable-icon ${
              isSaving ? 'rich-editor-components-action-disabled' : ''
            }`}
            id="rich-editor-components-draggable-icon"
            style={isDragging ? { display: 'flex' } : {}}
          />
        </Tooltip>
      )}
      {showActionButtons && !isDragging && (
        <div
          className={`rich-editor-components-action-buttons ${toolbarClassName}`}
          style={showImageActions ? setActionButtonsPosition() : null}>
          {toolbar || null}

          <Col>
            {showEdit && onEdit && <span onClick={onEdit}>Edit</span>}
            {showDuplicate && <span onClick={duplicate}>Duplicate</span>}

            {showDelete && (
              <Popover
                content={
                  <Col className="table-delete-popover">
                    <h3 className="ant-popover-title">Delete {componentType}?</h3>
                    <Divider />
                    <Row>
                      <Button
                        size="middle"
                        type="button"
                        className="Prosprich-editor-components-popover-confirm"
                        onClick={remove}>
                        Delete
                      </Button>
                      <Button
                        size="middle"
                        type="button"
                        className="Prosprich-editor-components-popover-cancel"
                        onClick={() => handleDeleteVisibleChange(false)}>
                        Cancel
                      </Button>
                    </Row>
                  </Col>
                }
                placement={showTestimonialActions ? 'bottomRight' : 'bottomLeft'}
                overlayClassName="Prosprich-editor-components-popover"
                trigger="click"
                visible={toolOpen === 'delete'}
                onVisibleChange={handleDeleteVisibleChange}>
                <Tooltip title="Delete">
                  <span className={isSaving ? 'rich-editor-components-action-disabled' : ''}>
                    <DeleteIcon className="delete-icon" />
                  </span>
                </Tooltip>
              </Popover>
            )}
          </Col>
          {showTableActions && (
            <>
              <Col>
                <span onClick={addColumn}>
                  <Tooltip title="Add Column">
                    <AddColumnIcon className="column-icon" />
                  </Tooltip>
                </span>
                <span onClick={addRow}>
                  <Tooltip title="Add Row">
                    <AddRowIcon className="row-icon" />
                  </Tooltip>
                </span>
              </Col>
              <Col>
                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <SketchPicker
                      color={config.topRowColor}
                      onChange={(color) =>
                        onColorChange({ color, topRowSketch: true, rowSketch: false })
                      }
                    />
                  }>
                  Top Row{' '}
                  <div className="color-circle" style={{ backgroundColor: config.topRowColor }} />
                </Popover>

                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <SketchPicker
                      color={config.rowColor}
                      onChange={(color) =>
                        onColorChange({ color, topRowSketch: false, rowSketch: true })
                      }
                    />
                  }>
                  Row <div className="color-circle" style={{ backgroundColor: config.rowColor }} />
                </Popover>
              </Col>
            </>
          )}
          {config && config?.columns && (
            <Col>
              <span onClick={() => handleColumn(config?.columns === 1 ? 2 : 1)}>
                {' '}
                {config?.columns === 1 ? `2 Columns` : `1 Column`}{' '}
              </span>
            </Col>
          )}
        </div>
      )}
      {showDrag && (
        <>{!isDragging ? children : <Divider className="rich-editor-components-dragger" />}</>
      )}
      {showTableActions && (
        <>
          {showActionButtons && !isDragging && (
            <div className="table-resize-container">
              <Tooltip title="Resize" placement="bottom">
                <ResizeIcon id="table-drag-icon" onMouseDown={onDragMouseDown} />
              </Tooltip>
            </div>
          )}
        </>
      )}
      {!showDrag && children}
    </div>
  );
};

ComponentWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  blockKey: PropTypes.string.isRequired,
  showDrag: PropTypes.bool,
  showDuplicate: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  showActionButtons: PropTypes.bool,
  showTableActions: PropTypes.bool,
  showTestimonialActions: PropTypes.bool,
  duplicate: PropTypes.func,
  remove: PropTypes.func,
  addRow: PropTypes.func,
  addColumn: PropTypes.func,
  onColorChange: PropTypes.func,
  onEdit: PropTypes.func,
  onReplace: PropTypes.func,
  onHyperLinkSave: PropTypes.func,
  hyperlink: PropTypes.string,
  handleColumn: PropTypes.func,
  handleEditComponent: PropTypes.func,
  isDraggable: PropTypes.bool,
  config: PropTypes.instanceOf(Object),
  showImageActions: PropTypes.bool,
  onShadowChange: PropTypes.func,
  shadow: PropTypes.instanceOf(Object),
  saveShadow: PropTypes.func,
  border: PropTypes.instanceOf(Object),
  saveBorder: PropTypes.func,
  onBorderChange: PropTypes.func,
  isSaving: PropTypes.bool,
  handleImageAlignment: PropTypes.func,
  componentWrapperStyle: PropTypes.instanceOf(Object),
  setDropDisabled: PropTypes.func,
  toolbarClassName: PropTypes.string,
};

export default ComponentWrapper;
