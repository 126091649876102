/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { Select, Layout, Row, Form, Col, Divider } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ColorBox from 'components/ColorBox';
import ResetItem from '../ResetItem/ResetItem';
import {
  fontListEnglish,
  fontListHebrew,
  fontSizes,
  defaultHebrewTitleSettings,
  defaultTitleSettings,
  defaultSubTitleSettings,
  defaultHebrewBodySettings,
  defaultBodySettings,
} from 'pages/Proposal/constants/constants';
import { fontLoadedVar } from 'graphql/cache';
import { loadProposalFont } from 'helpers/proposal';
import FontSelect from './FontSelect';

import './FontsMenu.scss';

const { Option } = Select;

// store all font and their weights as key value pair for faster search
const fontFamilyWeightsMap = [...fontListEnglish, ...fontListHebrew].reduce((acc, f) => {
  acc[f.family] = f.weights;
  return acc;
}, {});

// get the weights for selected font
const getFontWeights = (family) => fontFamilyWeightsMap[family] || [400];

const getFont = (defaultSettings, draftFont = {}) => {
  draftFont.weights = getFontWeights(draftFont.family);

  const f = { ...defaultSettings, ...draftFont };
  // convert string weight to number
  if (typeof f?.weight === 'string') {
    f.weight = Number(f.weight) || 400;
  }

  if (f.weights && f.weights.indexOf(f.weight) === -1) {
    [f.weight] = f.weights;
  }

  return f;
};

const FontsMenu = ({ language, saveProposal, draft, wixEditor, user }) => {
  const fontLoaded = useReactiveVar(fontLoadedVar);

  // these are the lastest value to be respected
  // TODO these can be stored in proposal level instead of just in the side font menu
  const [cache, setCache] = useState({ count: {} });

  const [titleFont, setTitleFont] = useState({ ...defaultTitleSettings });
  const [subTitleFont, setSubTitleFont] = useState({ ...defaultSubTitleSettings });
  const [bodyFont, setBodyFont] = useState({ ...defaultBodySettings });
  const [fontList, setFontList] = useState([]);
  const [customFontList, setCustomFontList] = useState([]);
  const [defaultFont, setDefaultFont] = useState();

  const handlePrelistLoad = (langChange) => {
    let prelist = [];

    if (language?.toLowerCase() === 'hebrew' || langChange === 'hebrew') {
      prelist = fontListHebrew;
    } else {
      prelist = fontListEnglish;
    }

    if (!fontLoaded) {
      fontLoadedVar(true);
      loadProposalFont(prelist, langChange || language, user);
    }

    setFontList(prelist);
    setCustomFontList(user?.uploadedFonts || []);
    setDefaultFont(user?.profile?.defaultProposalFont || 'Poppins');
  };

  const handleEffect = (draftValue, key) => {
    // if draftValue is empty, return
    if (!draftValue) {
      return;
    }
    // if cache exists, return (those are lastest value in UI)
    if (cache?.[key]) {
      // if draftValue = cache
      if (_.isEqual(draftValue, cache[key])) {
        setCache((prev) => {
          const _cache = { ...prev };

          if (_cache.count?.[key] === 3) {
            // on 3rd hit remove cache
            delete _cache.count[key];
            delete _cache[key];
          } else {
            _cache.count[key] = (_cache.count[key] || 0) + 1;
          }

          return _cache;
        });
      }
      // updating values from draftValue might cause glitch in ui
      // as those are probably old values, wait untill cache is cleared
      return;
    }

    if (key === 'titleFont') {
      setTitleFont(getFont(defaultTitleSettings, draftValue));
    } else if (key === 'subTitleFont') {
      setSubTitleFont(getFont(defaultSubTitleSettings, draftValue));
    } else if (key === 'bodyFont') {
      setBodyFont(getFont(defaultBodySettings, draftValue));
    }
  };

  useEffect(() => {
    handlePrelistLoad(language?.toLowerCase());

    if (
      titleFont &&
      language?.toLowerCase() === 'hebrew' &&
      (titleFont.family === 'Sharpsansno1 book' || titleFont.family === 'Droid Sans')
    )
      setTitleFont(defaultHebrewTitleSettings);

    if (
      subTitleFont &&
      language?.toLowerCase() === 'hebrew' &&
      (subTitleFont.family === 'Sharpsansno1 book' || subTitleFont.family === 'Droid Sans')
    )
      setSubTitleFont(defaultHebrewBodySettings);

    if (
      bodyFont &&
      language?.toLowerCase() === 'hebrew' &&
      (bodyFont.family === 'Sharpsansno1 book' || bodyFont.family === 'Droid Sans')
    )
      setBodyFont(defaultHebrewBodySettings);
  }, []);

  useEffect(() => {
    handleEffect(draft?.titleFont, 'titleFont');
  }, [draft.titleFont]);

  useEffect(() => {
    handleEffect(draft?.subTitleFont, 'subTitleFont');
  }, [draft.subTitleFont]);

  useEffect(() => {
    handleEffect(draft?.bodyFont, 'bodyFont');
  }, [draft.bodyFont]);

  const nearSimilarWeight = (weights, weight) => {
    // match close/similar weight if weight is not found in weights
    if (weight === 4000 || weights.includes(weight)) {
      return weight;
    }

    return weight < 500 ? weights[0] : nearSimilarWeight(weights, weight - 100);
  };

  const updateFont = (updateType, oldvalue, type, value) => {
    const newValue = { ...oldvalue, [type]: value };
    // on updating family update weights also
    if (type === 'family') {
      newValue.weights = getFontWeights(value);
      newValue.weight = nearSimilarWeight(newValue.weights, newValue.weight);
    }
    saveProposal({ [`draft.${updateType}`]: newValue });
    setCache((prev) => ({ ...prev, [updateType]: newValue }));
    return newValue;
  };

  const handleFontSave = (font, type, value) => {
    switch (font) {
      case 'title':
        setTitleFont((prev) => updateFont('titleFont', prev, type, value));
        return;
      case 'subtitle':
        setSubTitleFont((prev) => updateFont('subTitleFont', prev, type, value));
        return;
      case 'body':
        setBodyFont((prev) => updateFont('bodyFont', prev, type, value));
        return;
      default:
    }
  };

  const onReset = () => {
    let tempTitleFont, tempSubTitleFont, tempBodyFont;
    if (language?.toLowerCase() === 'hebrew') {
      tempTitleFont = defaultHebrewTitleSettings;
      tempSubTitleFont = defaultHebrewBodySettings;
      tempBodyFont = defaultHebrewBodySettings;
    } else {
      tempTitleFont = defaultTitleSettings;
      tempSubTitleFont = defaultSubTitleSettings;
      tempBodyFont = defaultBodySettings;
    }

    setTitleFont(tempTitleFont);
    setSubTitleFont(tempSubTitleFont);
    setBodyFont(tempBodyFont);

    setCache((prev) => ({
      ...prev,
      titleFont: tempTitleFont,
      subTitleFont: tempSubTitleFont,
      bodyFont: tempBodyFont,
    }));

    saveProposal({
      'draft.titleFont': tempTitleFont,
      'draft.subTitleFont': tempSubTitleFont,
      'draft.bodyFont': tempBodyFont,
    });
  };

  // call color change only once
  const debounceHandleFontSave = useRef(
    _.debounce((font, type, value) => {
      handleFontSave(font, type, value);
    }, 500)
  ).current;

  return (
    <Layout className="font-menu">
      <h5 className="header-text">Titles</h5>
      <Form layout="vertical">
        <Row>
          <Col md={24} lg={24} xl={24}>
            <FontSelect
              id="rhs-title-font-select"
              fontLists={fontList}
              customFonts={customFontList}
              defaultFont={defaultFont}
              font={titleFont}
              onChange={(value) => {
                handleFontSave('title', 'family', value);
              }}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col md={12} lg={12} xl={12}>
            <Form.Item label="Style" colon={false}>
              <Select
                placeholder="Extra Bold"
                value={titleFont.weight}
                allowClear
                suffixIcon={<CaretDownOutlined color="red" />}
                onChange={(value) => handleFontSave('title', 'weight', value)}>
                {titleFont.weights.map((item) => (
                  <Option key={item} value={item} className="">
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} lg={12} xl={12}>
            <Form.Item label="Font Size" colon={false}>
              <Select
                placeholder="24px"
                value={titleFont.fontSize}
                allowClear
                suffixIcon={<CaretDownOutlined color="red" />}
                onChange={(value) => handleFontSave('title', 'fontSize', value)}>
                {fontSizes.map((item) => (
                  <Option key={item} className="">
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} lg={12} xl={12}>
            <Form.Item label="Header Primary Color" colon={false}>
              <ColorBox
                color={titleFont.color1}
                onChange={(value) => debounceHandleFontSave('title', 'color1', value)}
              />
            </Form.Item>
          </Col>
          <Col md={12} lg={12} xl={12}>
            <Form.Item label="Section Title Color" colon={false}>
              <ColorBox
                color={titleFont.color3}
                onChange={(value) => debounceHandleFontSave('title', 'color3', value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Header Secondary color" colon={false}>
              <ColorBox
                color={titleFont.color2}
                onChange={(value) => debounceHandleFontSave('title', 'color2', value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider className="section-divider" />
      </Form>

      <h5 className="header-text">Subtitles</h5>
      <Form layout="vertical">
        <Row>
          <Col md={24} lg={24} xl={24}>
            <FontSelect
              id="rhs-subtitle-font-select"
              fontLists={fontList}
              customFonts={customFontList}
              defaultFont={defaultFont}
              font={subTitleFont}
              onChange={(value) => {
                handleFontSave('subtitle', 'family', value);
              }}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col md={12} lg={12} xl={12}>
            <Form.Item label="Style" colon={false}>
              <Select
                placeholder="Extra Bold"
                value={subTitleFont.weight}
                allowClear
                suffixIcon={<CaretDownOutlined color="red" />}
                onChange={(value) => handleFontSave('subtitle', 'weight', value)}>
                {subTitleFont.weights.map((item) => (
                  <Option key={item} className="">
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} lg={12} xl={12}>
            <Form.Item label="Font Size" colon={false}>
              <Select
                placeholder="24px"
                value={subTitleFont.fontSize}
                allowClear
                suffixIcon={<CaretDownOutlined color="red" />}
                onChange={(value) => handleFontSave('subtitle', 'fontSize', value)}>
                {fontSizes.map((item) => (
                  <Option key={item} className="">
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Subtitle Color" colon={false}>
              <ColorBox
                color={subTitleFont.color1}
                onChange={(value) => debounceHandleFontSave('subtitle', 'color1', value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider className="section-divider" />
      </Form>

      <h5 className="header-text">Body Text</h5>
      <Form layout="vertical">
        <Row>
          <Col md={24} lg={24} xl={24}>
            <FontSelect
              id="rhs-body-font-select"
              fontLists={fontList}
              customFonts={customFontList}
              defaultFont={defaultFont}
              font={bodyFont}
              onChange={(value) => {
                handleFontSave('body', 'family', value);
              }}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col md={12} lg={12} xl={12}>
            <Form.Item label="Style" colon={false}>
              <Select
                placeholder="Extra Bold"
                value={bodyFont.weight}
                allowClear
                suffixIcon={<CaretDownOutlined color="red" />}
                onChange={(value) => handleFontSave('body', 'weight', value)}>
                {bodyFont.weights.map((item) => (
                  <Option key={item} className="">
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} lg={12} xl={12}>
            <Form.Item label="Font Size" colon={false}>
              <Select
                placeholder="24px"
                value={bodyFont.fontSize}
                allowClear
                suffixIcon={<CaretDownOutlined color="red" />}
                onChange={(value) => handleFontSave('body', 'fontSize', value)}>
                {
                  // render till 60px
                  fontSizes.slice(0, wixEditor ? -4 : -2).map((item) => (
                    <Option key={item} className="">
                      {item}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} lg={12} xl={12}>
            <Form.Item label="Header Primary Color" colon={false}>
              <ColorBox
                color={bodyFont.color1}
                onChange={(value) => debounceHandleFontSave('body', 'color1', value)}
              />
            </Form.Item>
          </Col>
          <Col md={12} lg={12} xl={12}>
            <Form.Item label="Proposal Text Color" colon={false}>
              <ColorBox
                color={bodyFont.color2}
                onChange={(value) => debounceHandleFontSave('body', 'color2', value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <ResetItem onReset={onReset} />
    </Layout>
  );
};

FontsMenu.propTypes = {
  language: PropTypes.string.isRequired,
  saveProposal: PropTypes.func.isRequired,
  draft: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default FontsMenu;
