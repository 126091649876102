import React from 'react';
import _ from 'lodash';

import BoldIcon from 'components/Icons/BoldIcon';
import ItalicIcon from 'components/Icons/ItalicIcon';
import UnderlineIcon from 'components/Icons/UnderlineIcon';
import StrikeThroughIcon from 'components/Icons/StrikeThroughIcon';

import DirectionalIcon from 'components/Icons/DirectionalIcon';
import LeftAlignIcon from 'components/Icons/LeftAlignIcon';
import CenterAlignIcon from 'components/Icons/CenterAlignIcon';
import JustifiedAlignIcon from 'components/Icons/JustifiedAlignIcon';
import RightAlignIcon from 'components/Icons/RightAlignIcon';

import NoneBlockIcon from 'components/Icons/NoneBlockIcon';
import BulletBlockIcon from 'components/Icons/BulletBlockIcon';
import HyphenBlockIcon from 'components/Icons/HyphenBlockIcon';
import CheckBlockIcon from 'components/Icons/CheckBlockIcon';
import NumberedBlockIcon from 'components/Icons/NumberedBlockIcon';
import AlphaBlockIcon from 'components/Icons/AlphaBlockIcon';

const styleMap = {
  RIGHT: {
    textAlign: 'right',
    display: 'block',
    paddingBottom: '20px',
    marginBottom: '20px',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: 'rgb(204, 204, 204)',
    fontWeight: 'bold',
  },
  XXX: {},
  YYY: {
    float: 'right',
  },
  TOTES: {
    borderTop: '1px solid black',
    display: 'block',
    paddingBottom: '0',
    margin: '7px 0px',
    height: '0px',
  },
};

export const ELEMENTS_NOT_ALLOWED_IN_MULTI_COLUMN = [
  'add-price',
  'add-form',
  'add-testimonial',
  'add-goals',
  'add-table',
  'add-gallery',
];

const assistantFont = {
  family: 'Assistant',
  weights: [200, 300, 400, 500, 600, 700, 800],
};

const fontListEnglish = _.sortBy(
  _.map(
    [
      {
        family: 'Sharpsansno1 book',
        title: 'Sharp Sans No. 1',
        source: 'Custom',
        type: 'sans-serif',
        weights: [400, 700],
        italic: [400],
        fallback: [],
      },
      {
        family: 'Aclonica',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Alef',
        source: 'Google',
        weights: [400, 700],
        type: 'sans-serif',
      },
      {
        family: 'Allan',
        source: 'Google',
        weights: [400, 700],
        type: 'display',
      },
      {
        family: 'Allerta',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Allerta Stencil',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Amaranth',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Amatic SC',
        source: 'Google',
        weights: [400, 700],
        type: 'handwriting',
      },
      {
        family: 'Annie Use Your Telescope',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Anonymous Pro',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'monospace',
      },
      {
        family: 'Anton',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Architects Daughter',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Arimo',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Artifika',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'Arvo',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'serif',
      },
      {
        family: 'Arya',
        source: 'Google',
        weights: [400, 700],
        type: 'sans-serif',
      },
      {
        family: 'Asap',
        source: 'Google',
        weights: [400, 500, 600, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Asset',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        ...assistantFont,
        source: 'Google',
        type: 'sans-serif',
      },
      {
        family: 'Astloch',
        source: 'Google',
        weights: [400, 700],
        type: 'display',
      },
      {
        family: 'Bangers',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Bentham',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'Bevan',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Bigshot One',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Bowlby One',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Bowlby One SC',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Brawler',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'Cabin',
        source: 'Google',
        weights: [400, 500, 600, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Calligraffitti',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Candal',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Cantarell',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Cantata One',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'Cantora One',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Caudex',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'serif',
      },
      {
        family: 'Cedarville Cursive',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Cherry Cream Soda',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Chewy',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Coda',
        source: 'Google',
        weights: [400, 800],
        type: 'display',
      },
      {
        family: 'Coming Soon',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Copse',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'Corben',
        source: 'Google',
        weights: [400, 700],
        type: 'display',
      },
      {
        family: 'Covered By Your Grace',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Crafty Girls',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Crimson Text',
        source: 'Google',
        weights: [400, 600, 700, '700italic'],
        type: 'serif',
      },
      {
        family: 'Crushed',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Cuprum',
        source: 'Google',
        weights: [400, 500, 600, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Damion',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Dancing Script',
        source: 'Google',
        weights: [400, 500, 600, 700],
        type: 'handwriting',
      },
      {
        family: 'David Libre',
        source: 'Google',
        weights: [400, 500, 700],
        type: 'serif',
      },
      {
        family: 'Dawning of a New Day',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Didact Gothic',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Dosis',
        source: 'Google',
        weights: [200, 300, 400, 500, 600, 700, 800],
        type: 'sans-serif',
      },
      {
        family: 'DM Serif Display',
        source: 'Google',
        weights: [400, 'italic'],
        type: 'serif',
      },
      {
        family: 'EB Garamond',
        source: 'Google',
        weights: [400, 500, 600, 700, 800, '800italic'],
        type: 'serif',
      },
      {
        family: 'Exo',
        source: 'Google',
        weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
        type: 'sans-serif',
      },
      {
        family: 'Expletus Sans',
        source: 'Google',
        weights: [400, 500, 600, 700, '700italic'],
        type: 'display',
      },
      {
        family: 'Fontdiner Swanky',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Forum',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Francois One',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Frank Ruhl Libre',
        source: 'Google',
        weights: [300, 400, 500, 700, 900],
        type: 'serif',
      },
      {
        family: 'Geo',
        source: 'Google',
        weights: [400, 'italic'],
        type: 'sans-serif',
      },
      {
        family: 'Georgia',
        source: 'Websafe',
        type: 'serif',
        weights: [400, 700],
        italic: 'same',
        fallback: ['Times', 'Times New Roman'],
      },
      {
        family: 'Give You Glory',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Goblin One',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Goudy Bookletter 1911',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'Gravitas One',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Gruppo',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Hammersmith One',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Heebo',
        source: 'Google',
        weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
        type: 'sans-serif',
      },
      {
        family: 'Holtwood One SC',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'Homemade Apple',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Impact',
        source: 'Websafe',
        type: 'sans-serif',
        weights: [700],
        italic: 'same',
        fallback: ['Haettenschweiler', 'Franklin Gothic Bold', 'Charcoal'],
      },
      {
        family: 'Inconsolata',
        source: 'Google',
        weights: [200, 300, 400, 500, 600, 700, 800, 900],
        type: 'monospace',
      },
      {
        family: 'Indie Flower',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'IM Fell DW Pica',
        source: 'Google',
        weights: [400, 'italic'],
        type: 'serif',
      },
      {
        family: 'IM Fell DW Pica SC',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'IM Fell Double Pica',
        source: 'Google',
        weights: [400, 'italic'],
        type: 'serif',
      },
      {
        family: 'IM Fell Double Pica SC',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'IM Fell English',
        source: 'Google',
        weights: [400, 'italic'],
        type: 'serif',
      },
      {
        family: 'IM Fell English SC',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'IM Fell French Canon',
        source: 'Google',
        weights: [400, 'italic'],
        type: 'serif',
      },
      {
        family: 'IM Fell French Canon SC',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'IM Fell Great Primer',
        source: 'Google',
        weights: [400, 'italic'],
        type: 'serif',
      },
      {
        family: 'IM Fell Great Primer SC',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'Irish Grover',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Istok Web',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Josefin Sans',
        source: 'Google',
        weights: [100, 200, 300, 400, 500, 600, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Josefin Slab',
        source: 'Google',
        weights: [100, 300, 400, 600, 700, '700italic'],
        type: 'serif',
      },
      {
        family: 'Judson',
        source: 'Google',
        weights: [400, 700],
        type: 'serif',
      },
      {
        family: 'Jura',
        source: 'Google',
        weights: [300, 400, 500, 600, 700],
        type: 'sans-serif',
      },
      {
        family: 'Just Another Hand',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Just Me Again Down Here',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Kameron',
        source: 'Google',
        weights: [400, 700],
        type: 'serif',
      },
      {
        family: 'Kenia',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Kranky',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Kreon',
        source: 'Google',
        weights: [300, 400, 500, 600, 700],
        type: 'serif',
      },
      {
        family: 'Kristi',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'La Belle Aurore',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Lato',
        source: 'Google',
        weights: [100, 300, 400, 700, 900],
        type: 'sans-serif',
      },
      {
        family: 'League Script',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Lekton',
        source: 'Google',
        weights: [400, 700],
        type: 'sans-serif',
      },
      {
        family: 'Limelight',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Lobster',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Lobster Two',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'display',
      },
      {
        family: 'Lora',
        source: 'Google',
        weights: [400, 500, 600, 700, '700italic'],
        type: 'serif',
      },
      {
        family: 'Love Ya Like A Sister',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Loved by the King',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Luckiest Guy',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Maiden Orange',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Mako',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Maven Pro',
        source: 'Google',
        weights: [400, 500, 600, 700, 800, 900],
        type: 'sans-serif',
      },
      {
        family: 'Meddon',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'MedievalSharp',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Merriweather',
        source: 'Google',
        weights: [300, 400, 700, 900],
        type: 'serif',
      },
      {
        family: 'Metrophobic',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Michroma',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Miltonian',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Miltonian Tattoo',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Miriam Libre',
        source: 'Google',
        weights: [400, 700],
        type: 'sans-serif',
      },
      {
        family: 'Modern Antiqua',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Molengo',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Monofett',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Montserrat',
        source: 'Google',
        weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
        type: 'sans-serif',
      },
      {
        family: 'Mountains of Christmas',
        source: 'Google',
        weights: [400, 700],
        type: 'display',
      },
      {
        family: 'Neucha',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Neuton',
        source: 'Google',
        weights: [200, 300, 400, 700, 800],
        type: 'serif',
      },
      {
        family: 'News Cycle',
        source: 'Google',
        weights: [400, 700],
        type: 'sans-serif',
      },
      {
        family: 'Nixie One',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Nobile',
        source: 'Google',
        weights: [400, 500, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Nova Cut',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Nova Flat',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Nova Mono',
        source: 'Google',
        weights: [400],
        type: 'monospace',
      },
      {
        family: 'Nova Oval',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Nova Round',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Nova Script',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Nova Slim',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Nova Square',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Nunito',
        source: 'Google',
        weights: [200, 300, 400, 600, 700, 800, 900],
        type: 'sans-serif',
      },
      {
        family: 'Nunito Sans',
        source: 'Google',
        weights: [200, 300, 400, 600, 700, 800, 900],
        type: 'sans-serif',
      },
      {
        family: 'Old Standard TT',
        source: 'Google',
        weights: [400, 700],
        type: 'serif',
      },
      {
        family: 'Open Sans',
        source: 'Google',
        weights: [300, 400, 600, 700, 800, '800italic'],
        type: 'sans-serif',
      },
      {
        family: 'Open Sans Condensed',
        source: 'Google',
        weights: [300, 700],
        type: 'sans-serif',
      },
      {
        family: 'Orbitron',
        source: 'Google',
        weights: [400, 500, 600, 700, 800, 900],
        type: 'sans-serif',
      },
      {
        family: 'Oswald',
        source: 'Google',
        weights: [200, 300, 400, 500, 600, 700],
        type: 'sans-serif',
      },
      {
        family: 'Over the Rainbow',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Pacifico',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Palatino Linotype',
        source: 'Websafe',
        type: 'serif',
        weights: [400, 700],
        italic: 'same',
        fallback: ['Book Antiqua', 'Palatino'],
      },
      {
        family: 'Patrick Hand',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Paytone One',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Permanent Marker',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Philosopher',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Play',
        source: 'Google',
        weights: [400, 700],
        type: 'sans-serif',
      },
      {
        family: 'Playfair Display',
        source: 'Google',
        weights: [400, 500, 600, 700, 800, 900],
        type: 'serif',
      },
      {
        family: 'Podkova',
        source: 'Google',
        weights: [400, 500, 600, 700, 800],
        type: 'serif',
      },
      {
        family: 'Poppins',
        source: 'Google',
        weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
        type: 'sans-serif',
      },
      {
        family: 'PT Mono',
        source: 'Google',
        weights: [400],
        type: 'monospace',
      },
      {
        family: 'PT Sans',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'PT Sans Caption',
        source: 'Google',
        weights: [400, 700],
        type: 'sans-serif',
      },
      {
        family: 'PT Sans Narrow',
        source: 'Google',
        weights: [400, 700],
        type: 'sans-serif',
      },
      {
        family: 'PT Serif',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'serif',
      },
      {
        family: 'PT Serif Caption',
        source: 'Google',
        weights: [400, 'italic'],
        type: 'serif',
      },
      {
        family: 'Puritan',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Quattrocento',
        source: 'Google',
        weights: [400, 700],
        type: 'serif',
      },
      {
        family: 'Quattrocento Sans',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Quicksand',
        source: 'Google',
        weights: [300, 400, 500, 600, 700],
        type: 'sans-serif',
      },
      {
        family: 'Radley',
        source: 'Google',
        weights: [400, 'italic'],
        type: 'serif',
      },
      {
        family: 'Raleway',
        source: 'Google',
        weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
        type: 'sans-serif',
      },
      {
        family: 'Redressed',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Reenie Beanie',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Rock Salt',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Rokkitt',
        source: 'Google',
        weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
        type: 'serif',
      },
      {
        family: 'Roboto',
        source: 'Google',
        weights: [100, 300, 400, 500, 700, 900],
        type: 'sans-serif',
      },
      {
        family: 'Rubik',
        source: 'Google',
        weights: [300, 400, 500, 600, 700, 800, 900],
        type: 'sans-serif',
      },
      {
        family: 'Ruslan Display',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Schoolbell',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Secular One',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Shadows Into Light',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Shanti',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Sigmar One',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Six Caps',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Slackey',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Smythe',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Source Sans Pro',
        source: 'Google',
        weights: [200, 300, 400, 600, 700, 900],
        type: 'sans-serif',
      },
      {
        family: 'Special Elite',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Stardos Stencil',
        source: 'Google',
        weights: [400, 700],
        type: 'display',
      },
      {
        family: 'Sue Ellen Francisco',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Suez One',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'Sunshiney',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Swanky and Moo Moo',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Syncopate',
        source: 'Google',
        weights: [400, 700],
        type: 'sans-serif',
      },
      {
        family: 'Tahoma',
        source: 'Websafe',
        type: 'sans-serif',
        weights: [400, 700],
        italic: 'same',
        fallback: ['Verdana', 'Segoe'],
      },
      {
        family: 'Tangerine',
        source: 'Google',
        weights: [400, 700],
        type: 'handwriting',
      },
      {
        family: 'Tenor Sans',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'The Girl Next Door',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Tinos',
        source: 'Google',
        weights: [400, 700, '700italic'],
        type: 'serif',
      },
      {
        family: 'Times New Roman',
        source: 'Websafe',
        type: 'serif',
        weights: [400, 700],
        italic: 'same',
        fallback: ['TimesNewRoman', 'Times', 'Baskerville', 'Georgia'],
      },
      {
        family: 'Trebuchet MS',
        source: 'Websafe',
        type: 'sans-serif',
        weights: [400, 700],
        italic: 'same',
        fallback: ['Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', 'Tahoma'],
      },
      {
        family: 'Ubuntu',
        source: 'Google',
        weights: [300, 400, 500, 700, '700italic'],
        type: 'sans-serif',
      },
      {
        family: 'Ultra',
        source: 'Google',
        weights: [400],
        type: 'serif',
      },
      {
        family: 'Unkempt',
        source: 'Google',
        weights: [400, 700],
        type: 'display',
      },
      {
        family: 'UnifrakturCook',
        source: 'Google',
        weights: [700],
        type: 'display',
      },
      {
        family: 'UnifrakturMaguntia',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Varela',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Varela Round',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Verdana',
        source: 'Websafe',
        type: 'sans-serif',
        weights: [400, 700],
        // italic: 'same',
        // fallback: ['Geneva'],
      },
      {
        family: 'Vibur',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Vollkorn',
        source: 'Google',
        weights: [400, 500, 600, 700, 800, 900],
        type: 'serif',
      },
      {
        family: 'VT323',
        source: 'Google',
        weights: [400],
        type: 'monospace',
      },
      {
        family: 'Waiting for the Sunrise',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Wallpoet',
        source: 'Google',
        weights: [400],
        type: 'display',
      },
      {
        family: 'Walter Turncoat',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
      {
        family: 'Wire One',
        source: 'Google',
        weights: [400],
        type: 'sans-serif',
      },
      {
        family: 'Yanone Kaffeesatz',
        source: 'Google',
        weights: [200, 300, 400, 500, 600, 700],
        type: 'sans-serif',
      },
      {
        family: 'Zeyada',
        source: 'Google',
        weights: [400],
        type: 'handwriting',
      },
    ],
    (x) => {
      x.title = x.title || x.family;
      return x;
    }
  ),
  (a) => {
    return a.family.toLowerCase();
  }
);

const fontListHebrew = _.sortBy(
  _.map(
    [
      {
        family: 'Arimo',
        source: 'Google',
        weights: [400, 700],
        italic: [],
        fallback: [],
      },
      {
        ...assistantFont,
        source: 'Google',
        italic: [],
        fallback: [],
      },
      {
        family: 'Heebo',
        source: 'Google',
        weights: [100, 300, 400, 500, 700, 800, 900],
        italic: [],
        fallback: [],
      },
      {
        family: 'Open Sans Hebrew',
        source: 'Custom',
        weights: [300, 400, 700, 800],
        italic: [],
        fallback: [],
      },
      {
        family: 'Alef',
        source: 'Google',
        weights: [400, 700],
        italic: [],
        fallback: [],
      },
      {
        family: 'Rubik',
        type: 'Roboto',
        source: 'Google',
        weights: [300, 400, 500, 700, 900],
        italic: [],
        fallback: [],
      },
    ],
    (x) => {
      x.title = x.title || x.family;
      return x;
    }
  ),
  (a) => {
    return a.family.toLowerCase();
  }
);

const fontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900];

const fontSizes = [
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '28px',
  '30px',
  '32px',
  '36px',
  '48px',
  '60px',
  '72px',
  '90px',
];

const INLINE_STYLES = [
  { style: 'BOLD', text: 'Bold', icon: <BoldIcon /> },
  { style: 'ITALIC', text: 'Italic', icon: <ItalicIcon /> },
  { style: 'UNDERLINE', text: 'Underline', icon: <UnderlineIcon /> },
  {
    style: 'STRIKETHROUGH',
    text: 'Strikethrough',
    icon: <StrikeThroughIcon />,
  },
];

const ALIGNMENT_STYLES = [
  {
    style: 'editor-align-left',
    type: 'align',
    icon: <LeftAlignIcon />,
    text: 'Left align',
  },
  {
    style: 'editor-align-center',
    type: 'align',
    icon: <CenterAlignIcon />,
    text: 'Center align',
  },
  {
    style: 'editor-align-right',
    type: 'align',
    icon: <RightAlignIcon />,
    text: 'Right align',
  },
  {
    style: 'editor-align-justify',
    type: 'align',
    icon: <JustifiedAlignIcon />,
    text: 'Justify',
  },
  {
    style: 'editor-direction',
    type: 'direction',
    icon: <DirectionalIcon />,
    text: 'Direction',
  },
];

const BLOCK_TYPES = [
  { style: 'none', icon: <NoneBlockIcon />, custom: true },
  { style: 'unordered-list-item', icon: <BulletBlockIcon /> },
  { style: 'unordered-list-item-hyphen', icon: <HyphenBlockIcon />, custom: 'unordered-list-item' },
  { style: 'unordered-list-item-check', icon: <CheckBlockIcon />, custom: 'unordered-list-item' },
  { style: 'ordered-list-item', icon: <NumberedBlockIcon /> },
  { style: 'ordered-list-item-alpha', icon: <AlphaBlockIcon />, custom: 'ordered-list-item' },
];

const LINE_STYLES = [
  { style: 'line-height__1-0', text: '1.0' },
  { style: 'line-height__1-2', text: '1.2' },
  { style: 'line-height__1-5', text: '1.5' },
  { style: 'line-height__2-0', text: '2.0' },
];

const defaultHebrewTitleSettings = {
  ...assistantFont,
  title: 'Assistant',
  weight: 400,
  color1: 'white',
  color2: '#d2d2d2',
  color3: 'black',
  fontSize: '36px',
};

const defaultTitleSettings = {
  title: 'Poppins',
  family: '"Poppins", sans-serif',
  weight: 400,
  weights: [400, 700],
  color1: 'white',
  color2: '#d2d2d2',
  color3: 'black',
  fontSize: '36px',
};

const defaultSubTitleSettings = {
  title: 'Poppins',
  family: '"Poppins", sans-serif',
  weight: 400,
  weights: [400, 700],
  color1: 'gray',
  fontSize: '18px',
};

const defaultHebrewSubTitleSettings = {
  ...assistantFont,
  title: 'Assistant',
  weight: 400,
  color1: 'gray',
  fontSize: '18px',
};

const defaultHebrewBodySettings = {
  ...assistantFont,
  title: 'Assistant',
  weight: 400,
  color1: 'white',
  color2: '#4a4a4a',
  color3: 'black',
  fontSize: '20px',
};

const defaultBodySettings = {
  title: 'Poppins',
  family: '"Poppins", sans-serif',
  weight: 400,
  weights: [400, 700],
  color1: 'white',
  color2: '#4a4a4a',
  color3: 'black',
  fontSize: '20px',
};

const defaultFormFields = [
  {
    key: 'Please fill the form below',
    custom: false,
    head: true,
  },
  {
    key: 'First Name',
    value: '',
  },
  {
    key: 'Last Name',
    value: '',
  },
  {
    key: 'ID Number',
    value: '',
  },
  {
    key: 'Birthday',
    value: '',
  },
  {
    key: 'Date',
    value: '',
  },
  {
    key: 'Company Name',
    value: '',
  },
  {
    key: 'URL',
    value: '',
  },
  {
    key: 'Phone Number',
    value: '',
  },
  {
    key: 'Email',
    value: '',
  },
  {
    key: 'Note',
    value: '',
  },
];

const numberWords = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];

export {
  styleMap,
  fontListEnglish,
  fontListHebrew,
  fontWeights,
  fontSizes,
  INLINE_STYLES,
  ALIGNMENT_STYLES,
  BLOCK_TYPES,
  LINE_STYLES,
  defaultHebrewTitleSettings,
  defaultTitleSettings,
  defaultSubTitleSettings,
  defaultHebrewSubTitleSettings,
  defaultHebrewBodySettings,
  defaultBodySettings,
  defaultFormFields,
  numberWords,
};
