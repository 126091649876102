import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="19" height="19" viewBox="0 0 19.9 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#05348B"
      d="M10.2,0.9c-2.4,0-4.7,1-6.3,2.7L2.6,2.3C2.1,1.8,1.2,2.1,1.1,2.9L1.1,7.3c0,0.5,0.4,0.9,0.9,0.9L6.3,8 C7,8,7.4,7.1,6.9,6.6L5.3,5c1.3-1.3,3-2.1,4.9-2.1c3.7,0,6.7,3,6.7,6.6s-3,6.6-6.7,6.6c-1.9,0-3.7-0.8-5-2.2l-1.5,1.3 c1.6,1.8,4,2.9,6.5,2.9c4.8,0,8.7-3.8,8.7-8.6C18.9,4.8,15,0.9,10.2,0.9z"
    />
  </svg>
);

const ResetIcon = (props) => <Icon component={svg} {...props} />;

export default ResetIcon;
