import React, { useState } from 'react';
import { Modal, Input, Form, Button } from 'antd';
import PropTypes from 'prop-types';

import CloseIcon from 'components/Icons/CloseIcon';

const PromptForm = ({ placeholder, onPressEnter, onValueChange, maxLength }) => {
  return (
    <Form
      onValuesChange={({ input }) => {
        onValueChange(input);
      }}>
      <Form.Item name="input">
        <Input
          placeholder={placeholder}
          onPressEnter={onPressEnter}
          autoFocus
          maxLength={maxLength}
        />
      </Form.Item>
    </Form>
  );
};

const Prompt = ({
  placeholder,
  modalProps = {},
  visible,
  title,
  maxLength,
  handleConfirm,
  handleCancel,
  confirmText,
  cancelText,
}) => {
  const [value, setValue] = useState('');
  return (
    <Modal
      className="confirm-modal"
      visible={visible}
      closeIcon={<CloseIcon className="close-icon" />}
      getContainer={false}
      onCancel={handleCancel}
      footer={null}
      {...modalProps}>
      <h3 className="title">{title}</h3>
      <div className="divider" />

      <div className="body">
        <PromptForm
          placeholder={placeholder}
          onPressEnter={() => handleConfirm(value)}
          onValueChange={(value) => setValue(value)}
          maxLength={maxLength}
        />
      </div>

      <div className="button-wrapper">
        <Button
          className="large-btn secondary-btn"
          type="primary"
          onClick={() => handleConfirm(value)}>
          {confirmText}
        </Button>
        <Button className="large-btn grey-btn" type="primary" onClick={handleCancel}>
          {cancelText}
        </Button>
      </div>
    </Modal>
  );
};

Prompt.defaultProps = {
  visible: false,
  showIcon: true,
  placeholder: '',
};

Prompt.propTypes = {
  title: PropTypes.string.isRequired,
  modalProps: PropTypes.instanceOf(Object),
  placeholder: PropTypes.string,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

export default Prompt;
