import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col, Avatar, Menu, Dropdown } from 'antd';

import { getUserShortName } from 'helpers/users';
import DeleteIcon from 'components/Icons/DeleteIcon';
import MenuIcon from 'components/Icons/MenuIcon';
import EditIcon from 'components/Icons/EditIcon';

import './UserItem.scss';

const Item = styled(Row)`
  border-left: 4px solid #2f2c88;
`;

const UserItem = ({
  userName,
  email,
  position,
  totalProposals,
  createdDate,
  image,
  deleteUser,
  editUser,
}) => {
  const handleClick = ({ key }) => {
    if (key === 'edit') {
      editUser();
    } else if (key === 'delete') {
      deleteUser();
    }
  };

  const menu = () => {
    return (
      <Menu onClick={handleClick} className="users-menu-items">
        <Menu.Item className="edit-text" key="edit">
          <EditIcon /> Edit
        </Menu.Item>
        <Menu.Item className="delete-text" key="delete">
          <DeleteIcon /> Delete
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Item className="user-item" justify="middle" gutter={8}>
      <Col>
        {image ? (
          <Avatar src={image} size={30} />
        ) : (
          <Avatar size={30}>{getUserShortName({ name: userName })}</Avatar>
        )}
      </Col>

      <Col sm={24} md={5} className="user-info">
        <h4>{userName}</h4>
        <p>{email}</p>
      </Col>
      <Col sm={24} md={3} className="user-position">
        <p className="position">
          <span className="position-title">Job &nbsp;</span>
          <span className="position-value">{position}</span>
        </p>
      </Col>

      <Col sm={24} md={3} className="user-proposal">
        <p className="proposal">
          <span className="position-title">Proposals &nbsp;</span>
          <span className="proposal-value">{totalProposals}</span>
        </p>
      </Col>

      <Col flex="auto" className="last-col">
        <div className="user-created">
          <p className="created">
            <span className="position-title">Created &nbsp;</span>
            <span className="created-value"> {createdDate}</span>
          </p>
        </div>
      </Col>
      <Col flex="{0 0 100px}" className="user-actions">
        <Dropdown
          className="proposal-item-menu"
          overlay={menu}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          trigger={['click', 'hover']}>
          <a
            className="ant-dropdown-link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            <MenuIcon />
          </a>
        </Dropdown>
      </Col>
    </Item>
  );
};

UserItem.propTypes = {
  image: '',
};

UserItem.propTypes = {
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  totalProposals: PropTypes.number.isRequired,
  createdDate: PropTypes.string.isRequired,
  image: PropTypes.string,
  deleteUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
};

export default UserItem;
