import React from 'react';

import PropTypes from 'prop-types';

import { Layout, Tabs, Col } from 'antd';
import { LayoutMenu, ElementsMenu, FontsMenu, LibraryMenu } from './components';
import DoubleArrowIcon from 'components/Icons/DoubleArrowIcon';

import './ToolBarSideMenu.scss';

const { TabPane } = Tabs;

const ToolBarSideMenu = ({
  collapse,
  setCollapse,
  proposal,
  saveProposal,
  libraryType,
  dragStart,
  activeSideMenuKey,
  changeToolBarSideMenu,
  handleToolbarElements,
  dragEnd,
  showAttachmentModal,
  setDroppable,
  isSection,
  addNewSectionFromLibrary,
  sectionRef,
  openVariablesList,
  setOpenVariablesList,
  scrollingTo,
  setScrollingTo,
  disablePreview,
  setDisablePreview,
  wixEditor,
  templateWixEditor,
  user,
}) => {
  return (
    <Layout.Sider
      className={`toolbar-side-menu main-menu ${collapse && 'ant-layout-sider-is-collapsed'}`}>
      {!collapse && (
        <div className="toolbar-side-menu-closing-mask" onClick={() => setCollapse(true)} />
      )}
      <Tabs
        className="tabs toolbar-side-menu-tab"
        hideAdd
        activeKey={activeSideMenuKey}
        onTabClick={(key) => changeToolBarSideMenu({ key })}>
        <TabPane tab="ELEMENTS" key="elements">
          <ElementsMenu
            handleToolbarElements={handleToolbarElements}
            dragStart={dragStart}
            dragEnd={dragEnd}
            showAttachmentModal={showAttachmentModal}
            setDroppable={setDroppable}
            saveProposal={saveProposal}
            variables={proposal?.draft?.variables}
            openVariablesList={openVariablesList}
            setOpenVariablesList={setOpenVariablesList}
            scrollingTo={scrollingTo}
            setScrollingTo={setScrollingTo}
            language={proposal?.language || 'english'}
            isSection={isSection}
            wixEditor={wixEditor}
            templateWixEditor={templateWixEditor}
            wixPricing={proposal?.wixPricing || {}}
          />
        </TabPane>
        <TabPane tab="LIBRARY" key="library">
          <LibraryMenu
            sectionRef={sectionRef}
            libraryType={libraryType}
            dragStart={dragStart}
            dragEnd={dragEnd}
            setDroppable={setDroppable}
            isSection={isSection}
            saveProposal={saveProposal}
            sectionName={isSection ? proposal?.draft?.sectionorder[0] || 'header' : 'header'}
            coverType={proposal?.draft?.titleFont?.cover || 'top'}
            addNewSectionFromLibrary={addNewSectionFromLibrary}
            handleToolbarElements={handleToolbarElements}
            wixEditor={wixEditor}
            templateWixEditor={templateWixEditor}
          />
        </TabPane>
        {!isSection && (
          <>
            <TabPane tab="FONT" key="font">
              <FontsMenu
                draft={proposal?.draft}
                language={proposal?.language?.toLowerCase() || 'english'}
                saveProposal={saveProposal}
                wixEditor={wixEditor}
                user={user}
              />
            </TabPane>
            <TabPane tab="LAYOUT" key="layout">
              <LayoutMenu
                proposal={proposal}
                saveProposal={saveProposal}
                disablePreview={disablePreview}
                setDisablePreview={setDisablePreview}
              />
            </TabPane>
          </>
        )}
      </Tabs>
      <Col className={`ant-layout-sider-collapse ${collapse && `ant-layout-sider-collapse-open`}`}>
        <DoubleArrowIcon
          className={collapse ? 'ant-layout-sider-collapse-expand' : ''}
          onClick={() => setCollapse(!collapse)}
        />
      </Col>
    </Layout.Sider>
  );
};

ToolBarSideMenu.defaultProps = {
  collapse: false,
  setCollapse: () => {},
  activeSideMenuKey: 'elements',
  isSection: false,
  disablePreview: false,
  setDisablePreview: () => {},
  wixEditor: false,
  templateWixEditor: false,
  user: '',
};

ToolBarSideMenu.propTypes = {
  proposal: PropTypes.instanceOf(Object).isRequired,
  saveProposal: PropTypes.func.isRequired,
  handleToolbarElements: PropTypes.func.isRequired,
  activeSideMenuKey: PropTypes.string,
  changeToolBarSideMenu: PropTypes.func.isRequired,
  libraryType: PropTypes.string.isRequired,
  dragStart: PropTypes.func.isRequired,
  collapse: PropTypes.bool,
  isSection: PropTypes.bool,
  disablePreview: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  setDisablePreview: PropTypes.func,
  setCollapse: PropTypes.func,
  showAttachmentModal: PropTypes.func.isRequired,
  dragEnd: PropTypes.func.isRequired,
  addNewSectionFromLibrary: PropTypes.func.isRequired,
  sectionRef: PropTypes.instanceOf(Object).isRequired,
  wixEditor: PropTypes.bool,
  templateWixEditor: PropTypes.bool,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default ToolBarSideMenu;
