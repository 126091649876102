/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 9C0 8.44772 0.447715 8 1 8H11C11.5523 8 12 8.44772 12 9C12 9.55228 11.5523 10 11 10H1C0.447715 10 0 9.55228 0 9Z"
      fill="#05034D"
    />
    <path
      d="M3 5L6 3L9 5"
      stroke="#05034D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 13L6 15L3 13"
      stroke="#05034D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SplitSectionIcon = (props) => <Icon component={svg} {...props} />;

export default SplitSectionIcon;
