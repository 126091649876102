import { gql } from '@apollo/client';

export const GET_ALL_PROPOSALS = gql`
  query FetchProposals(
    $limit: Float
    $offset: Float
    $search: String
    $selectedDate: [String!]
    $filterItems: [FilterItems!]
    $filterTags: FilterTags!
    $spamUrl: String = null
    $teamList: [TeamList!]
  ) {
    fetchProposals(
      limit: $limit
      offset: $offset
      search: $search
      selectedDate: $selectedDate
      filterItems: $filterItems
      filterTags: $filterTags
      spamUrl: $spamUrl
      teamList: $teamList
    ) {
      proposalsCount
      proposalsStats(selectedDate: $selectedDate) {
        approvedCount
        notDraftCount
      }
      proposals {
        _id
        pid
        uid
        auid
        pnum
        name
        state
        isManager
        isOwner
        language
        channel
        client {
          contact
          name
          desc
          address
          text
          email
        }
        draft {
          header
          variables
        }
        quotes {
          minPrice
          maxPrice
        }
        seenTime
        seenCount
        lastSeen
        date
        version
        project {
          name
          type
          othertype
          desc
        }
        approvedmanually
        tags
        deliverables
        pricing
        sawyou
        sawmilestones
        priceSeperator
        signature {
          clientIP
        }
        openLogs {
          date
          clientIP
          duration
          watched
          deviceInfo {
            deviceType
            deviceOS
            deviceBrowser
            deviceName
            deviceAgent
          }
          type
          event
          recipient
        }
        sharedViaMail
        emailSettings {
          publishedProposalToProspect {
            status
            sent
            date
            reminder
            customize {
              subjectLine {
                RTL
                value
              }
              previewText {
                RTL
                value
              }
              emailBody {
                RTL
                value
              }
              buttonText {
                RTL
                value
              }
              reminder
            }
          }
          reminderToProspect {
            status
            sent
            date
            reminder
            customize {
              subjectLine {
                RTL
                value
              }
              previewText {
                RTL
                value
              }
              emailBody {
                RTL
                value
              }
              buttonText {
                RTL
                value
              }
              reminder
            }
          }
          secondReminderToProspect {
            status
            sent
            date
            reminder
            customize {
              subjectLine {
                RTL
                value
              }
              previewText {
                RTL
                value
              }
              emailBody {
                RTL
                value
              }
              buttonText {
                RTL
                value
              }
              reminder
            }
          }
          signedPDFToProspect {
            status
            sent
            date
            reminder
            customize {
              subjectLine {
                RTL
                value
              }
              previewText {
                RTL
                value
              }
              emailBody {
                RTL
                value
              }
              buttonText {
                RTL
                value
              }
              reminder
            }
          }
          recipients {
            firstName
            lastName
            email
          }
        }
        fonts {
          family
          weights
          source
          title
          format
          url
        }
        fonts {
          family
          weights
          source
          title
          format
          url
        }
      }
    }
  }
`;

export const GET_SINGLE_PROPOSAL = gql`
  query FetchProposal($_id: String, $pid: String, $cid: String) {
    fetchProposal(_id: $_id, pid: $pid, cid: $cid) {
      _id
      pid
      uid
      auid
      pnum
      name
      state
      channel
      draft
      email
      edited
      client {
        contact
        name
        desc
        address
        text
        email
      }
      seenTime
      seenCount
      lastSeen
      openLogs {
        date
        clientIP
        duration
        watched
        deviceInfo {
          deviceType
          deviceOS
          deviceBrowser
          deviceName
          deviceAgent
        }
      }
      date
      project {
        name
        type
        othertype
        name
        desc
        goals
      }
      approvedmanually
      version
      sawyou
      pricing
      deliverables
      milestones
      sawmilestones
      superPowers
      curr
      priceSeperator
      decimalPrecision
      language
      expiryDate
      expiryMessage
      currency
      callButton
      contactButton
      contactnumber
      enablePayment
      clientPayableAmount
      clientPayableLabel
      clientPayableCurrency
      clientPayableLink
      editEvent
      enableAITool
      userway
      creatorUserway
      scripts
      chatway
      chatwayScripts
      stripe
      payment {
        status
        amount
        currency
        payment_intend
        created
      }
      published
      disableSig
      signatureEmails
      selectedOption
      prevState
      payedfor
      dateFormat
      redirectTo
      redirectAfter
      proposalRedirectAfter
      proposalRedirectTo
      sigOptions
      contactOptions {
        option
        value
        enabled
      }
      sigtype
      signature {
        name
        myname
        additionalSignature
        signatureBackgroundColor
        signatureTextColor
        additionalSignatureBackgroundColor
        additionalSignatureTextColor
        email
        date
        clientIP
        device
      }
      attachments {
        name
        type
        url
        size
      }
      isEditing {
        name
        uid
        tabID
      }
      importedProposal
      spamUrls {
        url
        blocked
      }
      wixConnectedInstance
      fromUserTemplate
      isBasicTemplate
      wixProposalId
      wixPricing {
        lineItems {
          quantity
          name
          description
          price
          pricesBreakdown {
            totalPriceAfterTax
            taxDetails {
              taxRate
              taxableAmount
              taxable
              totalTax
              taxName
              rateBreakdown {
                rate
                taxableAmount
                tax
              }
            }
            totalDiscount
            price
            priceBeforeDiscounts
            lineItemPrice
            fullPrice
          }
          itemType {
            preset
          }
        }
        appliedDiscounts {
          discountType
          lineItemIds
        }
        additionalFees {
          name
          priceBeforeTax
          taxDetails {
            taxRate
            taxableAmount
            taxable
            totalTax
            taxName
            rateBreakdown {
              rate
              taxableAmount
              tax
            }
          }
          price
        }
        calculatedTotals {
          priceSummary {
            total
            subtotal
            discount
            additionalFees
          }
        }
        paymentSettings {
          type
          singlePaymentSchedule {
            dueDate
            dueDateOffset
            issueDate
            deposit {
              percentage
              amount
            }
            paymentStatus
            amountPaid
          }
          multiplePaymentSchedule {
            items {
              dueDate
              dueDateOffset
              issueDate
              deposit {
                percentage
                amount
              }
              issueDateOffset
              title
              percentage
              amount
              paymentStatus
              amountPaid
            }
          }
        }
        currencyCode
        locale {
          country
          language
        }
      }
      isWixTemplate
      sharedViaMail
      emailSettings {
        publishedProposalToProspect {
          status
          sent
          date
          reminder
          customize {
            subjectLine {
              RTL
              value
            }
            previewText {
              RTL
              value
            }
            emailBody {
              RTL
              value
            }
            buttonText {
              RTL
              value
            }
            reminder
          }
        }
        reminderToProspect {
          status
          sent
          date
          reminder
          customize {
            subjectLine {
              RTL
              value
            }
            previewText {
              RTL
              value
            }
            emailBody {
              RTL
              value
            }
            buttonText {
              RTL
              value
            }
            reminder
          }
        }
        secondReminderToProspect {
          status
          sent
          date
          reminder
          customize {
            subjectLine {
              RTL
              value
            }
            previewText {
              RTL
              value
            }
            emailBody {
              RTL
              value
            }
            buttonText {
              RTL
              value
            }
            reminder
          }
        }
        signedPDFToProspect {
          status
          sent
          date
          reminder
          customize {
            subjectLine {
              RTL
              value
            }
            previewText {
              RTL
              value
            }
            emailBody {
              RTL
              value
            }
            buttonText {
              RTL
              value
            }
            reminder
          }
        }
        recipients {
          firstName
          lastName
          email
        }
      }
      fonts {
        family
        weights
        source
        title
        format
        url
      }
    }
  }
`;

export const FETCH_OR_CREATE_WIX_PROPOSAL = gql`
  query fetchOrCreateWixProposal(
    $wixProposalId: String
    $type: String!
    $templateId: String
    $wixTemplateId: String
  ) {
    fetchOrCreateWixProposal(
      wixProposalId: $wixProposalId
      type: $type
      templateId: $templateId
      wixTemplateId: $wixTemplateId
    ) {
      _id
      pid
      uid
      auid
      wixProposalId
      pnum
      name
      state
      draft
      email
      noTemplate
      client {
        contact
        name
        desc
        address
        text
        email
      }
      seenTime
      seenCount
      lastSeen
      openLogs {
        date
        clientIP
        duration
        watched
        deviceInfo {
          deviceType
          deviceOS
          deviceBrowser
          deviceName
          deviceAgent
        }
      }
      date
      project {
        name
        type
        othertype
        name
        desc
        goals
      }
      approvedmanually
      version
      sawyou
      pricing
      deliverables
      milestones
      sawmilestones
      superPowers
      curr
      priceSeperator
      decimalPrecision
      language
      expiryDate
      expiryMessage
      currency
      callButton
      contactButton
      contactnumber
      enablePayment
      clientPayableAmount
      clientPayableLabel
      clientPayableCurrency
      clientPayableLink
      editEvent
      enableAITool
      userway
      creatorUserway
      scripts
      chatwayScripts
      stripe
      payment {
        status
        amount
        currency
        payment_intend
        created
      }
      published
      disableSig
      signatureEmails
      selectedOption
      prevState
      payedfor
      dateFormat
      redirectTo
      redirectAfter
      sigOptions
      contactOptions {
        option
        value
        enabled
      }
      sigtype
      signature {
        name
        myname
        additionalSignature
        signatureBackgroundColor
        signatureTextColor
        additionalSignatureBackgroundColor
        additionalSignatureTextColor
        email
        date
        clientIP
        device
      }
      attachments {
        name
        type
        url
        size
      }
      isEditing {
        name
        uid
        tabID
      }
      importedProposal
      wixConnectedInstance
      fromUserTemplate
      isBasicTemplate
      wixProposalId
      wixPricing {
        lineItems {
          quantity
          name
          description
          price
          pricesBreakdown {
            totalPriceAfterTax
            taxDetails {
              taxRate
              taxableAmount
              taxable
              totalTax
              taxName
              rateBreakdown {
                rate
                taxableAmount
                tax
              }
            }
            totalDiscount
            price
            priceBeforeDiscounts
            lineItemPrice
            fullPrice
          }
          itemType {
            preset
          }
        }
        appliedDiscounts {
          discountType
          lineItemIds
        }
        additionalFees {
          name
          priceBeforeTax
          taxDetails {
            taxRate
            taxableAmount
            taxable
            totalTax
            taxName
            rateBreakdown {
              rate
              taxableAmount
              tax
            }
          }
          price
        }
        calculatedTotals {
          priceSummary {
            total
            subtotal
            discount
            additionalFees
          }
        }
        paymentSettings {
          type
          singlePaymentSchedule {
            dueDate
            dueDateOffset
            issueDate
            deposit {
              percentage
              amount
            }
            paymentStatus
            amountPaid
          }
          multiplePaymentSchedule {
            items {
              dueDate
              dueDateOffset
              issueDate
              deposit {
                percentage
                amount
              }
              issueDateOffset
              title
              percentage
              amount
              paymentStatus
              amountPaid
            }
          }
        }
        currencyCode
        locale {
          country
          language
        }
      }
      isWixTemplate
    }
  }
`;

export const DOWNLOAD_PROPOSAL = gql`
  query DownloadProposal($origin: String, $proposalId: String, $cid: String) {
    downloadProposal(origin: $origin, proposalId: $proposalId, cid: $cid)
  }
`;

export const DOWNLOAD_PROPOSAL_PDF = gql`
  query DownloadProposalPDF($proposalId: String) {
    downloadProposalPDF(proposalId: $proposalId)
  }
`;

export const GET_PROPOSALS_CLIENTS = gql`
  query FetchProposalsQuery($uid: String!) {
    fetchProposals(uid: $uid) {
      proposals {
        _id
        pid
        uid
        client {
          contact
        }
      }
    }
  }
`;

export const PROPOSAL_CSV = gql`
  query fetchProposalCSVQuery($duration: String!) {
    fetchProposalCSV(duration: $duration) {
      name
      clientName
      watched
      opened
      status
      quote
      clientIP
      tags
    }
  }
`;

export const USER_PROPOSALS_SUMMARY = gql`
  query fetchUserProposalsSummaryQuery {
    fetchUserProposalsSummary {
      duration
      total
      pending
    }
  }
`;

export const GET_IMPORT_PROPOSALS = gql`
  query getImportProposalsQuery($uid: String!) {
    fetchProposals(uid: $uid) {
      proposals {
        _id
        pid
        uid
        draft {
          header
        }
        date
        version
        project {
          name
        }
      }
    }
  }
`;

export const GET_IMPORT_PROPOSAL = gql`
  query getImportProposalQuery($_id: String) {
    fetchProposal(_id: $_id) {
      _id
      pid
      uid
      auid
      draft
    }
  }
`;

export const GET_REDIRECTION_PROPOSAL = gql`
  query FetchProposalRedirection($domain: String, $proposal: String) {
    fetchProposalRedirection(domain: $domain, proposal: $proposal) {
      _id
      wixProposalId
    }
  }
`;

export const OPEN_AI_RECOMMENDATIONS = gql`
  query openAIRecommendationsQuery(
    $prompt: String!
    $tone: String
    $recommendationSize: String
    $command: String
    $enhance: Boolean
    $grammar: Boolean
  ) {
    openAIRecommendations(
      prompt: $prompt
      tone: $tone
      recommendationSize: $recommendationSize
      command: $command
      enhance: $enhance
      grammar: $grammar
    ) {
      text
    }
  }
`;

export const GET_SPAM_PROPOSALS = gql`
  query FetchProposals($limit: Float, $offset: Float, $spamUrl: String!) {
    fetchProposals(limit: $limit, offset: $offset, spamUrl: $spamUrl) {
      proposalsCount
      proposals {
        _id
        pid
        uid
        email
        pnum
        state
        date
        spamUrls {
          url
          blocked
        }
        project {
          name
        }
      }
    }
  }
`;
